<template>
    <div class="header__search__item">
        <span @click="modalVisible = true">{{ $t('labels.search') }}</span>
        <IconLoupe @click="modalVisible = true" />
    </div>
    <search-global-modal
        v-if="modalVisible"
        :site-handle="siteHandle"
        :search-page-url="searchPageUrl"
        @close="modalVisible = false"
        @keydown.esc="onEscPress"
    />
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import IconLoupe from '@/svg/icon-loupe.svg?component';
import SearchGlobalModal from '@/vue/components/search/global/SearchGlobalModal.vue';

defineProps({
    siteHandle: {
        type: String,
        required: true,
    },
    searchPageUrl: {
        type: String,
        required: true,
    },
});

const modalVisible = ref(false);

watch(modalVisible, (visible: boolean) => {
    if (visible) {
        document.body.classList.add('globalsearch--open');
    } else {
        document.body.classList.remove('globalsearch--open');
    }
});

const onEscPress = (e: KeyboardEvent) => {
    if (!modalVisible.value) {
        return;
    }

    e.preventDefault();
    e.stopPropagation();

    modalVisible.value = false;
};
</script>
