import { FormComponent } from '@/alpine/FormComponent';

export class CheckoutInformationFormComponent extends FormComponent {
    public useShippingAddressForBilling: boolean = true;
    public privacyPolicyAccepted: boolean = true;
    public showShippingNote: boolean = false;

    public init() {
        super.init();

        this.$watch('submitting', (submitting) => {
            if (submitting) {
                document.body.classList.add('show-global-spinner');
            } else {
                document.body.classList.remove('show-global-spinner');
            }
        });
    }
}
