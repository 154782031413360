<template>
    <Modal @close="emit('close')">
        <div class="global-search__overlay">
            <SearchField
                :placeholder="$t('labels.search_global_placeholder')"
                :site-handle="siteHandle"
                :search-page-url="searchPageUrl"
                :show-suggestion="true"
                :show-spellcheck="true"
            ></SearchField>
        </div>
    </Modal>
</template>

<script lang="ts" setup>
import Modal from '@/vue/components/Modal.vue';
import SearchField from "@/vue/components/search/SearchField.vue";

defineProps({
    siteHandle: {
        type: String,
        required: true,
    },
    searchPageUrl: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(['close']);
</script>
